import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getInstructors } from '../../apis/services';
const Instructors = () => {
    const { t } = useTranslation();
    const [ins, setIns] = useState([]);
    useEffect(() => {
        loadInstructors();
    }, []);
    const loadInstructors = async () => {
        try {
            const res = await getInstructors();
            setIns(res.data.instructors);
        } catch (error) {

        }
    }
    return (
        <div className="p-3 glass-card">
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('Manage Instructors')}</h4>
                </div>
                <div className='p-2'>
                    <Link to="/invite/instructors" className='link-btn'>Invite Instructors</Link>
                </div>
            </div>
            <div className="data-table mt-2">
                <DataTable
                    value={ins}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                >

                    <Column
                        field="code"
                        header={t('Code')}
                        sortable
                    />
                    <Column
                        field="alias_name"
                        header={t('Alias Name')}
                        sortable
                    />
                    <Column
                        field="email"
                        header={t('Email')}
                        sortable
                    />
                    <Column
                        field="phone"
                        header={t('Phone')}
                        sortable
                    />
                     <Column
                        field="service_charge"
                        header={t('Service Charge')}
                        body={(r)=>{
                            return(
                                <span>{r.service_charge}%</span>
                            );
                        }}
                        sortable
                    />
                    <Column
                        field="rating"
                        header={t('Rating')}
                        sortable
                    />
                    <Column
                        header="Actions"
                        body={(row) => {
                            return (
                                <div className='d-flex'>
                                    <Link
                                        state={JSON.stringify(row)}
                                        to='/instructors/view'
                                        className='link-icon-btn mx-1' severity='primary' id="edit-btn">
                                        <span className="material-symbols-outlined">
                                            visibility
                                        </span>
                                    </Link>
                                </div>
                            );
                        }}
                    />
                </DataTable>

            </div>
        </div>
    );
}

export default Instructors;