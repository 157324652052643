import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { getPaymentStatus } from './../../apis/services';
import { useTranslation } from 'react-i18next';
const RechargeWalletStatus = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [transaction, setTransaction] = useState();
    const {t} = useTranslation();
    useEffect(() => {
        loadGetPaymentStatus();
    }, []);
    const loadGetPaymentStatus = async () => {
        try {
            let chargeId = searchParams.get('tap_id');
            const res = await getPaymentStatus(chargeId);
            setTransaction(res.data.transction);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className="p-3">
            <div className="d-flex jcc">
                {
                    transaction !== undefined &&
                    <div className="glass-card p-5">
                        <h5 className='text-center'>{t('payment_receipt')}</h5>
                        <div className="text-center">
                            {
                                transaction.status === "CAPTURED" &&
                                <span className="material-symbols-outlined"
                                    style={{
                                        fontSize: 60,
                                        margin: 20,
                                        color: 'green'
                                    }}
                                >
                                    check_circle
                                </span>
                            }
                             {
                                transaction.status !== "CAPTURED" &&
                                <span className="material-symbols-outlined"
                                    style={{
                                        fontSize: 60,
                                        margin: 20,
                                        color: 'red'
                                    }}
                                >
                                    cancel
                                </span>
                            }

                        </div>
                        <ul className="well-data-list mt-4">
                            <li className="d-flex jscb mb-2">
                                <span>{t('amount')}</span>
                                <strong>KWD {transaction.amount.toFixed(3)}</strong>
                            </li>
                            <li className="d-flex jscb mb-2">
                                <span>{t('status')}</span>
                                <strong>{transaction.status}</strong>
                            </li>
                            <li className="d-flex jscb mb-2">
                                <span>{t('type')}</span>
                                <strong>{transaction.source.payment_method}</strong>
                            </li>
                            <li className="d-flex jscb mb-2">
                                <span>{t("track_id")}</span>
                                <strong style={{
                                    marginLeft: 50
                                }}>{transaction.id}</strong>
                            </li>

                        </ul>
                        <div className="text-center p-4">
                            <a href="/student/wallet" className='link-btn'>{t('go_to_wallet')}</a>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
export default RechargeWalletStatus;