import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { getRegisterData, checkEmail, sendOTP, registerUser, getCollegesByCountries } from './../../apis/services';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from "primereact/checkbox";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { InputOtp } from 'primereact/inputotp';
import Select from 'react-select';
const InsRegister = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [colleges, setColleges] = useState([]);
    const [tc, setTc] = useState(false);
    const [selectedColleges, setSelectedColleges] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [showOTP, setShowOTP] = useState(false);
    const [otp, setOtp] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState('');
    const [name, setName] = useState();
    const [password, setPassword] = useState();
    const [country, setCountry] = useState();
    const [countries, setCountries] = useState([]);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        loadRegisterData();
    }, []);
    const loadRegisterData = async () => {
        try {
            const res = await getRegisterData();
            setSubjects(res.data.subjects)
            setCountries(res.data.countries);
        } catch (error) {

        }
    }
    const triggerOTP = () => {
        if (tc) {
            setShowOTP(true);
            sendOTP({ email: email })
        }
    }
    const handleRegisterUser = async () => {
        try {
            let data = {
                userData: {
                    user: {
                        name: name,
                        nickName: name,
                        phone: phone,
                        email: email,
                        fcm: '',
                        role: 'teacher',
                        password: password,
                        country:JSON.stringify(country.id),
                    },
                    responseTime: 120,
                    colleges: selectedColleges.map((c) => c.id),
                    subjects: selectedSubjects.map((s) => s.id),
                },
                otp: otp,
            }
            setLoading(true);
            const res = await registerUser(data);
            setLoading(false);
            if (res.data.status === undefined || res.data.status === false) {
                alert('Incorrect OTP. Please enter valid OTP');
            } else {
                setShowOTP(false);
                setShowLogin(true);
            }
        } catch (error) {

        }
    }
    const checkUserEmail = async () => {
        try {
            let data = {
                email: email
            }
            const res = await checkEmail(data);
            if (res.data.dont_allow) {
                alert('Email already exist. Please try other email.');
                setEmail('');
            }
        } catch (error) {
            console.log(error);
        }

    }
    const loadColleges = async (list) => {
        try {
            let data = {
                cids: [list.id],
            }
            const res = await getCollegesByCountries(data);
            setColleges(res.data.colleges);

        } catch (error) {

        }
    }
    return (
        <div>
            <Modal
                show={showLogin}
            >
                <div className="p-4 text-center">
                    <div className="">
                        <h3 className='text-center'>Email Verfified..</h3>
                    </div>
                    <h6 className='text-center'>Your account has been created. Please Login.</h6>
                    <p className='text-center'>
                        <span className="material-symbols-outlined"
                            style={{
                                color: 'green',
                                fontSize: '3rem'
                            }}
                        >
                            check_circle
                        </span>
                    </p>
                    <Link to='/' className='btn p-btn'>Login</Link>
                </div>
            </Modal>
            <Modal
                show={showOTP}
            >
                <div className="p-4">
                    <div className="d-flex jcsb">
                        <h3>OTP Verfication</h3>

                        <Button raised className='icon-btn' severity='secondary' type='button'
                            onClick={() => {
                                setShowOTP(false);
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                    <h6>Enter the OTP sent to your email.</h6>
                    <div className='mt-4 mb-4'>
                        <InputOtp
                            length={6}
                            value={otp}
                            onChange={(e) => setOtp(e.value)}
                            integerOnly
                        />
                    </div>
                    <Button label={t("Verfiy OTP")} loading={loading} className='p-btn' id='register'
                        onClick={(e) => {
                            handleRegisterUser();
                        }}
                    />
                </div>
            </Modal>
            <div className='bg-img-vhicle'>
                <div className="row vh-center container-fluid">
                    <div className="col-md-8">
                        <div className='glass-card p-5 text-black m-3'>
                            <h3>TA-PAL - Register as Instructor</h3>
                            <form action="" method="post"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    triggerOTP();
                                }}
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="name" className='required mb-1'>Alias Name</label>
                                            <input type="text" className='form-control' required
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone" className=' mb-1'>Phone</label>
                                            <input type="text" className='form-control'
                                                value={phone}
                                                onChange={(e) => {
                                                    setPhone(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="email" className='required mb-1'>Email</label>
                                            <input type="email" className='form-control' required
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                }}
                                                value={email}
                                                onBlur={() => { checkUserEmail() }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="password" className='required mb-1'>Password</label>
                                            <input type="password" className='form-control' required
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="country" className='required mb-1'>Country(Target for Service)</label>
                                    <Select options={countries}
                                        getOptionLabel={(l) => l.country_name}
                                        getOptionValue={(v) => v.id}
                                        value={country}
                                        onChange={(e) => {
                                            setCountry(e)
                                            setSelectedColleges([]);
                                            loadColleges(e);
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collge" className='required mb-1'>Colleges</label>
                                    <MultiSelect
                                        value={selectedColleges}
                                        onChange={(e) => setSelectedColleges(e.value)}
                                        options={colleges}
                                        optionLabel="name"
                                        optionGroupLabel="cc"
                                        optionGroupChildren="colleges"
                                        className="pr-input"
                                        placeholder="Choose Colleges"
                                        display='chip'
                                        filter
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="major" className='required mb-1'>Subjects</label>
                                    <MultiSelect
                                        value={selectedSubjects}
                                        onChange={(e) => setSelectedSubjects(e.value)}
                                        options={subjects}
                                        optionLabel="name"
                                        optionGroupLabel="sc"
                                        optionGroupChildren="subjects"
                                        className="pr-input"
                                        placeholder="Choose Subjects"
                                        display='chip'
                                        filter
                                    />
                                </div>
                                <div className="flex align-items-center mt-3">
                                    <Checkbox
                                        inputId="ingredient1"
                                        name="tc"
                                        value={tc}
                                        onChange={() => {
                                            setTc(!tc);
                                        }}
                                        checked={tc}
                                    ></Checkbox>
                                    <label htmlFor="ingredient1" className="mx-2">
                                        <Link to="/terms-conditions">Agree Terms and Conditions</Link>
                                    </label>
                                </div>
                                <div className="form-group mt-3">
                                    <Button label={t("Register")} loading={loading} type='submit' className='p-btn' id='register' />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default InsRegister;