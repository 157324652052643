import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Galleria } from 'primereact/galleria';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { Rating } from 'primereact/rating';
import { Toast } from 'primereact/toast';
import { submitHRInstRating } from '../../apis/services';
import store from '../../redux/store';
const ViewHRAnswers = () => {
    const location = useLocation();
    const files = JSON.parse(location.state);
    const preview = JSON.parse(files.preview);
    const answers = JSON.parse(files.answers);
    const ans_desc = files.ans_desc;
    const isRated = files.rating;
    const ids = files.ids;
    const { t } = useTranslation();
    const [rating, setRating] = useState(3);
    const toastRef = useRef();
    const [showRating, setShowRating] = useState(isRated === null ? true : false);
    const user = store.getState().app.user.user;
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];
    const thumbnailTemplate = (a) => {
        return (
            <div>
                {
                    (a.type === "image/png" || a.type === "image/jpeg") &&
                    <img src={a.uri}
                        style={{
                            width: 50,
                            height: 50
                        }}
                    />
                }
                {
                    (a.type !== "image/png" && a.type !== "image/jpeg") &&
                    <span className="material-symbols-outlined"
                        style={{
                            fontSize: 40,
                            color: "#fff",
                            border: '2px solid #fff',
                            padding: 8,
                            borderRadius: 4,
                        }}
                    >
                        attach_file
                    </span>
                }
            </div>

        );
    }
    const itemTemplate = (a) => {
        return (
            <div className='p-3 text-center'>
                {
                    (a.type === "image/png" || a.type === "image/jpeg") &&
                    <a href={a.uri} target="_blank" rel="noopener noreferrer">
                        <img src={a.uri}
                            style={{
                                maxHeight: 400,
                                maxWidth: 400,
                                width: '100%',
                                textAlign: 'center',
                                alignSelf: 'center'
                            }}
                        />
                    </a>
                }
                {
                    (a.type !== "image/png" && a.type !== "image/jpeg") &&
                    <div
                        style={{
                            color: "#000",
                            border: '2px solid #000',
                            padding: 8,
                            borderRadius: 4,
                            maxHeight: 400,
                            maxWidth: 400,
                            height: '100%',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <a href={a.uri} target="_blank" rel="noopener noreferrer">
                            <span className="material-symbols-outlined"
                                style={{
                                    fontSize: 80,
                                }}
                            >
                                attach_file
                            </span>
                        </a>
                    </div>
                }

            </div>
        );
    }
    const handleRateHRAnswers = async () => {
        try {
            let data = {
                rating: rating,
                hw_id: ids.hr,
                ins_id: ids.ins,
            }
            await submitHRInstRating(data);
            toastRef.current.show({ severity: 'success', summary: t('rating_submitted'), detail: t('thanks_for_submitting_rating'), life: 3000 });
            setShowRating(false);
        } catch (error) {

        }
    }
    return (
        <div className="glass-card p-3">
            <Toast ref={toastRef} />
            <h4>{t('help_request_answers')}</h4>
            <h6><strong>{t('answer_description')}</strong></h6>
            <h5>{ans_desc}</h5>
            <div className="row">
                <div className="col-md-5">
                    <div className="card m-2">
                        <img src={preview.uri} alt=""
                            style={{
                                width: '100%'
                            }}
                        />
                    </div>
                    {
                        (showRating && user.role ==="student") &&
                        <div className="card p-4">
                            <h6 className='mb-3'>{t('please_rate_the_answers')}</h6>
                            <Rating cancel={false} value={rating}
                                offIcon={
                                    <span className="material-symbols-outlined"
                                        style={{
                                            fontSize: '3rem'
                                        }}
                                    >
                                        star
                                    </span>
                                }
                                onIcon={
                                    <span className="material-symbols-outlined"
                                        style={{
                                            color: "red",
                                            fontSize: '3rem'
                                        }}
                                    >
                                        stars
                                    </span>
                                }
                                onChange={(e) => {
                                    setRating(e.value);
                                }}
                            />
                            <Button label={t('submit_rating ')} className='mt-3 p-btn'
                                onClick={() => {
                                    handleRateHRAnswers();
                                }}
                            />
                        </div>
                    }


                </div>
                <div className="col-md-7">
                    <div className="card m-2 p-3">
                        <Galleria value={answers} responsiveOptions={responsiveOptions} numVisible={5}
                            item={itemTemplate} thumbnail={thumbnailTemplate}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ViewHRAnswers;