import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { Accordion } from 'react-bootstrap';

const MobileSideMenu = (props) => {
    const store = useStore();
    const user = store.getState().app.user.user;
    const [activePath, setActivePath] = useState('/');
    const [accordionActiveKey, setAccordionActiveKey] = useState('null');

    useEffect(() => {
        if (window.location.pathname.includes('users')) {
            setActivePath('/users');
        }
        else if (window.location.pathname.includes('hire-titles')) {
            setActivePath('/hire-titles');
        }
        else if (window.location.pathname.includes('departments')) {
            setActivePath('/departments');
        }
        else if (window.location.pathname.includes('positions')) {
            setActivePath('/positions');
        }
        else if (window.location.pathname.includes('questions')) {
            setActivePath('/questions');
        }
        else if (window.location.pathname.includes('candidates')) {
            setActivePath('/candidates');
        } else {
            setActivePath(window.location.pathname);
        }

    }, [useLocation().pathname]);
    const { t } = useTranslation();
    const handleAccordionToggle = (eventKey) => {
        // if (sizeToggler) {
        setAccordionActiveKey(accordionActiveKey === eventKey ? null : eventKey);
        // }
    };
    return (
        <div className='app-sidemenu-bar'>
            <li className={'app-sidemenu-bar-item ' + (activePath === "/" ? 'active' : '')}>
                <Link to="/" onClick={() => { props.hideSidemenu() }}>
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            home
                        </span>
                        <span className='link_title'>
                            {t('Home')}
                        </span>
                    </span>
                </Link>
            </li>
            {
                user.role === 'qa' &&
                <div>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('subjects') ? 'active' : ''}`}>
                        <Link to="/subjects" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    menu_book
                                </span>
                                <span className='link_title'>
                                    {t('subjects')}
                                </span>
                            </span>
                        </Link>
                    </li>

                    <li className={`app-sidemenu-bar-item ${activePath.includes('colleges') ? 'active' : ''}`}>
                        <Link to="/colleges" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    account_balance
                                </span>
                                <span className='link_title'>
                                    {t('colleges')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('majors') ? 'active' : ''}`}>
                        <Link to="/majors" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    school
                                </span>
                                <span className='link_title'>
                                    {t('majors')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('assignments') ? 'active' : ''}`}>
                        <Link to="/assignments" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    assignment
                                </span>
                                <span className='link_title'>
                                    {t('help_requests')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('hrs-quotes') ? 'active' : ''}`}>
                        <Link to="/hrs-quotes" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    schedule
                                </span>
                                <span className='link_title'>
                                    {t('all_quotes')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('students') ? 'active' : ''}`}>
                        <Link to="/students" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    person_book
                                </span>
                                <span className='link_title'>
                                    {t('students')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('instructors') ? 'active' : ''}`}>
                        <Link to="/instructors" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    person
                                </span>
                                <span className='link_title'>
                                    {t('instructors')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('payout-requests') ? 'active' : ''}`}>
                        <Link to="/payout-requests" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    sell
                                </span>
                                <span className='link_title'>
                                    {t('payout_requests')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('support') ? 'active' : ''}`}>
                        <Link to="/support-tickets/all" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    help
                                </span>
                                <span className='link_title'>
                                    {t('support_tickets')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('archived') ? 'active' : ''}`}>
                        <Link to="/archived" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    archive
                                </span>
                                <span className='link_title'>
                                    {t('archived')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('app-lables') ? 'active' : ''}`}>
                        <Link to="/app-lables" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    translate
                                </span>
                                <span className='link_title'>
                                    {t('App Lables')}
                                </span>
                            </span>
                        </Link>
                    </li>
                </div>
            }
            {
                user.role === 'teacher' &&
                <div>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('assignments') ? 'active' : ''}`}>
                        <Link to="/ins/assignments" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    assignment
                                </span>
                                <span className='link_title'>
                                    {t('help_requests')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('quotes') ? 'active' : ''}`}>
                        <Link to="/ins/quotes" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    history
                                </span>
                                <span className='link_title'>
                                    {t('quotes')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('wallet') ? 'active' : ''}`}>
                        <Link to="/instructor/wallet" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    wallet
                                </span>
                                <span className='link_title'>
                                    {t('wallet')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('payout-requests') ? 'active' : ''}`}>
                        <Link to="/ins/payout-requests" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    sell
                                </span>
                                <span className='link_title'>
                                    {t('payout_requests')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('support') ? 'active' : ''}`}>
                        <Link to="/support-tickets" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    help
                                </span>
                                <span className='link_title'>
                                    {t('support_tickets')}
                                </span>
                            </span>
                        </Link>
                    </li>
                </div>
            }
            {user.role === 'student' &&
                <div>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('assignments') ? 'active' : ''}`}>
                        <Link to="/student/assignments" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    menu_book
                                </span>
                                <span className='link_title'>
                                    {t('help_requests')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('quotes') ? 'active' : ''}`}>
                        <Link to="/student/quotes" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    assignment
                                </span>
                                <span className='link_title'>
                                    {t('my_quotes')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('wallet') ? 'active' : ''}`}>
                        <Link to="/student/wallet" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    wallet
                                </span>
                                <span className='link_title'>
                                    {t('wallet')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('payouts') ? 'active' : ''}`}>
                        <Link to="/student/payouts" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    send
                                </span>
                                <span className='link_title'>
                                    {t('payouts')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('support') ? 'active' : ''}`}>
                        <Link to="/support-tickets" onClick={() => { props.hideSidemenu() }}>
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    help
                                </span>
                                <span className='link_title'>
                                    {t('support_tickets')}
                                </span>
                            </span>
                        </Link>
                    </li>
                </div>
            }
            <li className={`app-sidemenu-bar-item ${activePath.includes('notifications') ? 'active' : ''}`}>
                <Link to="/notifications" onClick={() => { props.hideSidemenu() }}>
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            notifications
                        </span>
                        <span className='link_title'>
                            {t('notifications')}
                        </span>
                    </span>
                </Link>
            </li>
            {/* <li className={`app-sidemenu-bar-item ${activePath.includes('points') ? 'active' : ''}`}>
                <Accordion activeKey={accordionActiveKey}>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => handleAccordionToggle('1')}>
                            <Link className="link">
                                <img src="/assets/svg/hires.svg" alt="" />
                                Points
                            </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li>
                                    <Link to="/points/earn-points" onClick={() => { props.hideSidemenu() }} >Earn Points</Link>
                                </li>
                                <li>
                                    <Link to="/points/transaction-report" onClick={() => { props.hideSidemenu() }}>Transaction Report</Link>
                                </li>

                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </li> */}

        </div>
    );
}

export default MobileSideMenu;