import React, { useState, useEffect } from 'react';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import { getInstructorsProfile,updateServiceCharge } from './../../apis/services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from "primereact/inputswitch";
import { FilterMatchMode } from 'primereact/api';
import { useTranslation } from 'react-i18next';
import { getHRStatus } from '../assignments/assignment';
import { Button } from 'primereact/button';
import { Modal } from 'react-bootstrap';
import { InputNumber } from 'primereact/inputnumber';
const ViewInstructor = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const inst = JSON.parse(location.state);
    const [instProfile, setInstProfile] = useState();
    const [showChargeEdit, setShowChargeEdit] = useState(false);
    const [charge, setCharge] = useState(inst.service_charge);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    useEffect(() => {
        loadInstructorProfile();
    }, []);
    const loadInstructorProfile = async () => {
        try {
            let uid = inst.id;
            const res = await getInstructorsProfile(uid);
            setInstProfile(res.data);

        } catch (error) {
            console.log(error);

        }
    }
    const handleUpdateServiceCharge = async ()=>{
        try {
            let data = {
                charge:charge,
                ins:inst.id,
            }
            await updateServiceCharge(data);
            navigate('/instructors');
        } catch (error) {
            console.log(error);
            
        }
    }
    return (
        <div className="p-3">
            <Modal
                show={showChargeEdit}
            >
                <div className="p-3">
                    <div className="d-flex jscb aic">
                        <h6>{t('edit_service_charge')}</h6>
                        <Button className='icon-btn mx-1'
                            onClick={() => {
                                setShowChargeEdit(false);
                            }}
                        >
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </Button>
                    </div>
                    <form action=""
                        onSubmit={(e)=>{
                            e.preventDefault();
                            handleUpdateServiceCharge();
                        }}
                    >
                        <div className="form-group">
                            <label htmlFor="charge" className='mb-1 required'>{t('service_charge')}</label>
                            <InputNumber className='pr-input' useGrouping={false} value={charge}
                                onChange={(e) => {
                                    setCharge(e.value);
                                }}
                                required
                            />
                        </div>
                        <div className="form-group mt-3">
                            <Button type='submit' label={t('update')} className='p-btn'/>
                        </div>
                    </form>
                </div>
            </Modal>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <div className="glass-card p-3">
                        <ul className="well-data-list mt-4">
                            <li className="d-flex jscb mb-2">
                                <span>{t('alias_name')}</span>
                                <strong>{inst.alias_name}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('code')}</span>
                                <strong>{inst.code}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('email')}</span>
                                <strong>{inst.email}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('phone')}</span>
                                <strong>{inst.phone}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('rating')}</span>
                                <strong>{inst.rating} Star(s)</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('service_charge')}</span>
                                <span className='d-flex align-items-center'><strong>{inst.service_charge}%</strong>
                                    <Button className='icon-btn mx-1'
                                        onClick={() => {
                                            setShowChargeEdit(true);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            edit
                                        </span>
                                    </Button>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 mb-2">
                    <div className="glass-card p-3">
                        <h4>{t('wallet')}</h4>
                        <h5>{t('current_balance')}</h5>
                        {
                            instProfile !== undefined &&
                            <h3>KWD {instProfile.wallet.current_balance.toFixed(3)}</h3>
                        }
                        <div className='p-2 mb-2 mt-4 mb-4'>
                            <Link to="/payout-requests" className='link-btn'>{t('payout_requests')}</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mt-3">
                    <div className="glass-card p-3">
                        <h4>{t('help_requests')}</h4>
                        <h6>*{t('hrs_created_by_the_instructor')}</h6>
                        <div>
                            <DataTable
                                value={instProfile?.homeWorks}
                                filters={filters}
                                paginator
                                rows={10}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[10, 25, 50]}
                                dataKey="id"
                                selectionMode="checkbox"
                                filterDisplay="menu"
                                emptyMessage={t('no_records_exist')}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                header={
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <input className='form-control'
                                                placeholder='Search..'
                                                onChange={(e) => {
                                                    let _filters = { ...filters };
                                                    _filters['global'].value = e.target.value;
                                                    setFilters(_filters);
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            >

                                <Column
                                    field="id"
                                    header={t('hr_id')}
                                    sortable
                                    body={(r) => {
                                        return (
                                            <span>HR_{r.id}</span>
                                        );
                                    }}
                                />
                                <Column
                                    field="hw_title"
                                    header={t('subject')}
                                    sortable

                                />
                                <Column
                                    field="hw_type"
                                    header={t('type')}
                                    sortable
                                />
                                <Column
                                    header={t('urgent')}
                                    sortField='urgent'
                                    sortable
                                    body={(row) => {
                                        return (
                                            <InputSwitch checked={row.urgent === 1 ? true : false} />
                                        )
                                    }}
                                />

                                <Column
                                    header={t('status')}
                                    body={(r) => <span><strong>{getHRStatus(r)}</strong></span>}
                                />
                                <Column
                                    field="due_date"
                                    header={t('due_date')}
                                    sortable
                                />

                                <Column
                                    header={t('actions')}
                                    body={(row) => {
                                        return (
                                            <div className='d-flex'>
                                                <Link
                                                    to="/assignments/view"
                                                    state={row.id}
                                                    className='link-icon-btn mx-1' severity='info' id="edit-btn">
                                                    <span className="material-symbols-outlined">
                                                        visibility
                                                    </span>
                                                </Link>
                                            </div>
                                        )
                                    }}
                                />
                            </DataTable>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}
export default ViewInstructor;