import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Dropdown } from 'primereact/dropdown';
import { getSubjectsGroupedByCategory, getInstructorsBySubject, addHelpRequest } from '../../apis/services';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { getTimeStamp } from './../../helpers/helpers';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import store from '../../redux/store';
const AddAssignment = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toastRef = useRef();
    const user = store.getState().app.user;

    
    const [subjects, setSubjects] = useState([]);
    const [subject, setSubject] = useState();
    const [dueDate, setDueDate] = useState(new Date());
    const [hrType, setHrType] = useState();
    const [courseNameNumber, setCourseNameNumber] = useState("");
    const [instructors, setInstructors] = useState([]);
    const [selectedInstructors, setSelectedInstructors] = useState([]);
    const [courseInst, setCourseInst] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [render, setRender] = useState(false);
    const [urgent, setUrgent] = useState({
        value: 0,
        label: t('no')
    });

    const urgentOpts = [
        {
            value: 0,
            label: t('no'),
        },
        {
            value: 1,
            label: t('yes')
        }
    ];
    const hrTypes = [
        {
            value: 'Assignment',
            label: 'Assignment'
        },
        {
            value: 'Quiz',
            label: 'Quiz'
        },
        {
            value: 'Team Project',
            label: 'Team Project'
        },
        {
            value: 'Presentation',
            label: 'Presentation'
        },
        {
            value: 'Essay',
            label: 'Essay'
        },
        {
            value: 'Senior Project',
            label: 'Senior Project'
        },
        {
            value: 'Exam Preparation',
            label: 'Exam Preparation'
        },
        {
            value: 'Others',
            label: 'Others'
        },
    ];
    useEffect(() => {
        loadGetSubjectsGroupedByCategory();
    }, []);
    const loadGetSubjectsGroupedByCategory = async () => {
        try {
            const res = await getSubjectsGroupedByCategory();
            setSubjects(res.data.subjects);
        } catch (error) {

        }
    }
    const loadGetInstructorsBySubject = async (sub) => {
        try {
            let params = {};
            const res = await getInstructorsBySubject(params);
            let filteredBySub = res.data.instructors.filter((i) => JSON.parse(i.subjects).includes(sub.id));
            setInstructors(filteredBySub);
        } catch (error) {
            console.log(error);

        }
    }
    const removeAttachment = (index) => {
        let files = attachments;
        files.splice(index,1);
        setAttachments(files);
        setRender(!render);
    }
    const handleAddHelpRequest = async () => {
        try {
            let formData = new FormData();
            formData.append('hwTitle', subject.name);
            formData.append('subject', subject.id);
            formData.append('college_id', user.more.college_id);
            formData.append('urgent', urgent.value);
            formData.append('hwType', hrType.value);
            formData.append('courseNameNumber', courseNameNumber);
            formData.append('courseTeacher', courseInst);
            formData.append('dueDate', getTimeStamp(dueDate));
            formData.append('preferredInstructors', selectedInstructors.map((i) => i.id));
            for (const element of attachments) {
                formData.append('files[]', element);
            }
            await addHelpRequest(formData);
            toastRef.current.show({ severity: 'success', summary: 'Success', detail: "New Help request created", life: 3000 });
            navigate('/student/assignments')
        } catch (error) {
            toastRef.current.show({ severity: 'error', summary: 'Error', detail: 'Unable to create. Please try again', life: 3000 });
        }
    }
    return (
        <div className="p-3 glass-card">
            <Toast ref={toastRef} />
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('create_hr')}</h4>
                    <h6>{t('please_fill_add_details')}</h6>
                </div>
            </div>
            <hr />
            <form action=""
                onSubmit={(e) => {
                    e.preventDefault();
                    handleAddHelpRequest();
                }}
            >
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <div className="form-group">
                            <label htmlFor="subject" className='required mb-1'>{t('subject')}</label>
                            <Dropdown className='pr-input' required
                                placeholder={t('choose_subject')}
                                options={subjects}
                                optionGroupLabel='sc'
                                optionLabel='name'
                                optionGroupChildren='subjects'
                                value={subject}
                                onChange={(e) => {
                                    setSubject(e.value);
                                    loadGetInstructorsBySubject(e.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 mb-2">
                        <div className="form-group">
                            <label htmlFor="subject" className='required mb-1'>{t('due_date')}</label>
                            <Calendar className='pr-input' required minDate={new Date()}
                                value={dueDate}
                                onChange={(e) => {
                                    setDueDate(e.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col -md-3">
                        <div className="form-group">
                            <label htmlFor="subject" className='required mb-1'>{t('urgent')}</label>
                            <Select className='pr-input' required
                                options={urgentOpts}
                                value={urgent}
                                onChange={(e) => {
                                    setUrgent(e)
                                }}
                            />
                        </div>

                    </div>
                    <div className="col-md-4 mb-2">
                        <div className="form-group">
                            <label htmlFor="subject" className='required mb-1'>{t('request_type')}</label>
                            <Select className='pr-input' required
                                placeholder={t("select")}
                                options={hrTypes}
                                value={hrType}
                                onChange={(e) => {
                                    setHrType(e)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 mb-2">
                        <div className="form-group">
                            <label htmlFor="subject" className='mb-1'>{t('course_name_number')}</label>
                            <input type="text" name="" id="" className='form-control'
                                value={courseNameNumber}
                                onChange={(e) => {
                                    setCourseNameNumber(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 mb-2">
                        <div className="form-group">
                            <label htmlFor="subject" className=' mb-1'>{t('course_instructor')}</label>
                            <input type="text" name="" id="" className='form-control'
                                value={courseInst}
                                onChange={(e) => {
                                    setCourseInst(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 mb-2">
                        <div className="form-group">
                            <label htmlFor="subject" className=' mb-1'>{t('preferred_instructors')}</label>
                            <Select options={instructors}
                                getOptionLabel={(o) => {
                                    return (
                                        <span>{o.alias_name} ({o.rating} Star) </span>
                                    );
                                }}
                                getOptionValue={(o) => o.id}
                                isMulti={true}
                                value={selectedInstructors}
                                onChange={(e) => {
                                    setSelectedInstructors(e);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 mb-2">
                        <h5 className='mb-3 mt-3'>{t('upload_hr_supporting_attachments')}</h5>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="fileInput">
                                    <div className='fileinput-wrapper'>
                                        <span className="material-symbols-outlined"
                                            style={{
                                                fontSize: 50
                                            }}
                                        >
                                            cloud_upload
                                        </span>
                                    </div>
                                </label>
                                <input type="file" name="fileInput" id="fileInput" style={{ display: 'none' }}
                                    onChange={(e) => {
                                        let existing = attachments;
                                        existing.push(e.target.files[0]);
                                        setAttachments(existing);
                                        setRender(!render);
                                    }}
                                />
                            </div>
                            <div className="col-md-9">
                                <div className="attachments-preview mx-2">
                                    <div className='d-flex'

                                    >
                                        {attachments.map((a, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                   className='mx-2'
                                                >
                                                    <Button className='icon-btn'
                                                        onClick={()=>{
                                                            removeAttachment();
                                                        }}
                                                        type='button'
                                                        style={{
                                                            position:'relative',
                                                            top:50,
                                                            left:10,
                                                            zIndex:30
                                                        }}
                                                    >
                                                        <span className="material-symbols-outlined">
                                                            cancel
                                                        </span>
                                                    </Button>
                                                    {
                                                        (a.type === "image/png" || a.type === "image/jpeg") &&
                                                        <img src={URL.createObjectURL(a)} className='card'
                                                            style={{
                                                                borderRadius: 5,
                                                                width: 250,
                                                                height: 250
                                                            }}
                                                        />
                                                    }
                                                    {
                                                        (a.type !== "image/png" && a.type !== "image/jpeg") &&
                                                        <div
                                                            className='card'
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                width: 250,
                                                                height: 250,
                                                            }}
                                                        >
                                                            <span className="material-symbols-outlined"
                                                                style={{
                                                                    fontSize: 50
                                                                }}
                                                            >
                                                                attach_file
                                                            </span>
                                                        </div>
                                                    }

                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <Button label={t('save_hr')} className='p-btn' />
                    </div>
                </div>
            </form>
        </div>

    );
}
export default AddAssignment;