import React, { useState } from 'react';
import { getPaymentLink } from './../../apis/services';
import { Button } from 'primereact/button';
import store from '../../redux/store';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';

const RechargeWallet = () => {
    const {t} = useTranslation();
    const wallet = store.getState().app.user.wallet;
    const [amount, setAmount] = useState();
    const handleGetPaymentLink = async () => {
        try {
            let data = {
                amount:amount,
            };
            const res = await getPaymentLink(data);
            window.location.assign(res.data.url);
        } catch (error) {
            console.log(error);

        }
    }
    return (
        <div className="p-2">
            <div className="d-flex jcc">

                <div className="glass-card p-3">
                    <h3>{t('wallet')}</h3>
                    <h3>{t('current_balance')}  - KWD {wallet.current_balance.toFixed(3)}</h3>
                    <form action=""
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleGetPaymentLink();
                        }}
                    >
                        <div className="form-group">
                            <label htmlFor="amount" className='required mb-1'>{t('enter_amount')}</label>
                            <InputNumber className='pr-input' required
                                useGrouping={false}
                                placeholder={t('recharge_amount')}
                                value={amount}
                                onChange={(e)=>{
                                    setAmount(e.value)
                                }}
                            />
                        </div>
                        <div className="form-group mt-4">
                            <Button label={t('recharge')} className='p-btn' />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default RechargeWallet;