import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TabView, TabPanel } from 'primereact/tabview';
import ReceivedQuotes from './RecivedQuotes';
import AcceptedQuotes from './AcceptedQuotes';
import {getAllAssignmentQuotes} from '../../apis/services';

const AllQuotes = () => {
    const { t } = useTranslation();
    const [hrQuotes, setHrQuotes] = useState([]);
      useEffect(() => {
            loadAllAssignmentQuotes();
        }, []);
        const loadAllAssignmentQuotes = async () => {
            try {
                const res = await getAllAssignmentQuotes();
                setHrQuotes(res.data.quotes);
            } catch (error) {
    
            }
        }
    return (
        <div>
            <div className="glass-card p-3">
                <TabView>
                    <TabPanel header={t('quotes_sent_from_instructors')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">send</span> }}>
                        <ReceivedQuotes hrQuotes={hrQuotes} />
                    </TabPanel>
                    <TabPanel header={t('quotes_accepted_by_students')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">check_circle</span> }}>
                        <AcceptedQuotes hrQuotes={hrQuotes} />
                    </TabPanel>
                </TabView>
            </div>
        </div>
    );
}
export default AllQuotes;