import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Button } from 'primereact/button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {addSupportTicket} from '../../apis/services';

const CreateSupportTicket = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [stTitle,setStTitle] = useState();
    const [stType,setStType] = useState();
    const [hrID,setHrID] = useState();
    const [stDesc,setStDesc] = useState();
    const stTypes = [
        {
            label: t('unclear_answers'),
            value: t('unclear_answers'),
        },
        {
            label: t('due_date_over'),
            value: t('due_date_over'),
        },
        {
            label: t('not_accepting_answers'),
            value: t('not_accepting_answers'),
        },
    ];
    const handleAddSupportTicket = async () =>{
        try {
            let data = {
                stTitle:stTitle,
                stDesc:stDesc,
                stType:stType.value,
                hrID:hrID
            }
            await addSupportTicket(data);
            navigate(-1);
        } catch (error) {
            console.log(error);
            
        }
    }
    return (
        <div className="glass-card p-3">
            <h4>{t('create_support_tickets')}</h4>
            <h6>{t('fill_all_details')}</h6>
            <form action=""
                onSubmit={(e)=>{
                    e.preventDefault();
                    handleAddSupportTicket();
                }}
            >
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="st_title" className='mb-1 required'>{t('st_title')}</label>
                            <input type="text" name="st_title" id="st_title" className='form-control' required 
                            value={stTitle}
                            onChange={(e)=>{
                                setStTitle(e.target.value);
                            }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="hr_id" className='mb-1 required'>{t('hr_id')}</label>
                            <input type="text" name="hr_id" id="hr_id" className='form-control' required
                                value={hrID}
                                onChange={(e)=>{
                                    setHrID(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="st_type" className='mb-1 required'>{t('st_type')}</label>
                            <Select name="st_type" id="st_type" className='pr-input' required 
                                options={stTypes}
                                value={stType}
                                onChange={(e)=>{
                                    setStType(e)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="st_description" className='mb-1 required'>{t('st_description')}</label>
                            <textarea name="st_description" id="st_description" required className='form-control' rows={7}
                                value={stDesc}
                                onChange={(e)=>{
                                    setStDesc(e.target.value);
                                }}
                            ></textarea>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <Button label={t('create_st')} className='p-btn' type='submit' />
                    </div>
                </div>
            </form>
        </div>
    );
}
export default CreateSupportTicket;