import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { Link } from 'react-router-dom';
const ReceivedQuotes = (props) => {
    const { t } = useTranslation();
    const hrQuotes = props.hrQuotes.filter((i) => i.status === 0);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    return (
        <div>
            <div className="">
            <h6>{t('hr_quotes_from_inst')}</h6>
                <div className="data-table mt-2">
                    <DataTable
                        value={hrQuotes}
                        paginator
                        rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="menu"
                        emptyMessage={t('data_not_available')}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        filters={filters}
                        header={
                            <div className='row'>
                                <div className="col-md-6">
                                    <input className='form-control'
                                        placeholder='Search..'
                                        onChange={(e) => {
                                            let _filters = { ...filters };
                                            _filters['global'].value = e.target.value;
                                            setFilters(_filters);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    >
                        <Column
                            field="hw_id"
                            header={t('HR ID')}
                            sortable
                            body={(r) => {
                                return (
                                    <span>HR_{r.hw_id}</span>
                                );
                            }}
                        />
                        <Column
                            field="quote_amount"
                            body={(r) => {
                                return (
                                    <span>KWD {r.quote_amount.toFixed(3)}</span>
                                );
                            }}
                            header={t('Quote Amount')}
                            sortable
                        />

                        <Column
                            header={"Response Time"}
                            field='res_time'

                        />
                        <Column
                            field="quoteed_at"
                            header={t('date')}
                            sortable
                        />
                        <Column
                            header="Sent By"
                            field='ins_id'
                            body={(r) => {
                                return (
                                    <span>INS_00{r.ins_id}</span>
                                );
                            }}
                            sortable
                        />
                        <Column
                            field="status"
                            body={(r) => {
                                return (
                                    <span>{r.status === 0 ? "Sent" : "Accepted"}</span>
                                );
                            }}
                            header={t('Status')}
                            sortable
                        />
                        <Column
                            header={t('actions')}
                            body={(row) => {
                                return (
                                    <div className='d-flex'>
                                        <Link
                                            to="/assignments/view"
                                            state={row.hw_id}
                                            className='link-icon-btn' >
                                            <span className="material-symbols-outlined">
                                                visibility
                                            </span>
                                        </Link>
                                    </div>
                                )
                            }}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
export default ReceivedQuotes;