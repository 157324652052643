import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getInstructorsForInvite, sendInviationForInstructors } from '../../apis/services';
const InviteInstructors = () => {
    const { t } = useTranslation();
    const [ins, setIns] = useState([]);
    const [selectedIns, setSelectedIns] = useState([]);
    useEffect(() => {
        loadInstructors();
    }, []);
    const loadInstructors = async () => {
        try {
            const res = await getInstructorsForInvite();
            setIns(res.data.instructors);
        } catch (error) {

        }
    }
    const handleInstructorsInvitation = async () => {
        let data = {
            instructors: selectedIns.map((i)=> i.email),
        };

        await sendInviationForInstructors(data);
    }
    return (
        <div className="p-3 glass-card">
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('Invite Instructors')}</h4>
                </div>
                <div className='p-2'>
                    {/* <Link to="" className='link-btn'>Invite Instructors</Link> */}
                </div>
            </div>
            <div className="data-table mt-2">
                <DataTable
                    value={ins}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    selection={selectedIns}
                    onSelectionChange={(e) => {
                        setSelectedIns(e.value);
                    }}
                    footer={
                        <div className='row'>
                            <div className="col-md-3">
                                <Button className='p-btn' label='Invite Selected' type='submit'
                                    onClick={() => {
                                        handleInstructorsInvitation();
                                    }}
                                />
                            </div>
                        </div>

                    }
                >

                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column
                        field="name"
                        header={t('Name')}
                        sortable
                    />
                    <Column
                        field="email"
                        header={t('Email')}
                        sortable
                    />
                    <Column
                        field="college"
                        header={t('College')}
                        sortable
                    />
                    <Column
                        field="status"
                        header={t('Status')}
                        body={(row) => row.status === 0 ? "Not Invited" : 'Invited Already'}
                        sortable
                    />
                </DataTable>

            </div>
        </div>
    );
}

export default InviteInstructors;