import React from 'react';
import QAHome from './QAHome';
import INSHome from './INSHome';
import StudentHome from './StudentHome'
import{useStore} from 'react-redux';

const Home = () => {
    const store = useStore();
    const user = store.getState().app.user.user;
    return (
        <div className="">
            {
                user.role==='qa' &&
                <QAHome/>
            }
            {
                 user.role==='teacher' &&
                <INSHome/>
            }
            {
                 user.role==='student' &&
                <StudentHome/>
            }
            
        </div>
    );
}
export default Home;