import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getQAAssignments } from './../../apis/services';
import { TabView, TabPanel } from 'primereact/tabview';
import UnderReview from './tabs/UnderReview';
import Answered from './tabs/Answered';
import QCPassed from './tabs/QCPassed';
import Rejected from './tabs/Rejected';
const QAAssignments = () => {
    const { t } = useTranslation();
    const [assignments, setAssignments] = useState([]);

    useEffect(() => {
        loadQAHomeWorks();
    }, []);
    const loadQAHomeWorks = async () => {
        try {
            let data = {};
            const res = await getQAAssignments(data);
            setAssignments(res.data.homeworks);
        } catch (error) {

        }
    }
    return (
        <div>
            <div className="glass-card p-3">
                <div className="data-table mt-2">
                    <TabView>
                        <TabPanel header={t('under_review')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">schedule</span> }}>
                            <UnderReview assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('qc_passed')} leftIcon={() => { return <span className="material-symbols-outlined">library_add_check</span> }}>
                            <QCPassed assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('answered')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">check_circle</span> }}>
                            <Answered assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('rejected')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">cancel</span> }}>
                            <Rejected assignments={assignments} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
}
export default QAAssignments;