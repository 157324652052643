import React, { useState, useRef } from 'react';
import { updateUserPassword } from './../../apis/services';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';

const QAProfile = () => {
    const toastRef = useRef();
    const {t} = useTranslation();
    const [currentPassword, setCurrentPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const handleUpdateUserPassword = async () => {
        try {
            if (newPassword !== confirmPassword) {
                toastRef.current.show({ severity: 'error', summary: t('error'), detail: t('passwords_not_matching'), life: 3000 });
            } else {
                let data = {
                    newPassword: newPassword,
                    currentPassword: currentPassword
                }
                const res = await updateUserPassword(data);
                if (res.data.status) {
                    toastRef.current.show({ severity: 'success', summary: t('success'), detail: t('password_changed'), life: 3000 });
                } else {
                    toastRef.current.show({ severity: 'error', summary: t('error'), detail: t('password_change_error'), life: 3000 });
                }

            }

        } catch (error) {

        }
    }
    return (
        <div className="p-3">
            <Toast ref={toastRef} />
            <div className="row">
                <div className="col-md-8 mt-3">
                    <div className="glass-card p-3">
                        <h4>{t('qa_profile')}</h4>
                        <form action=""
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleUpdateUserPassword();
                            }}
                        >
                            <div className="form-group">
                                <label htmlFor="currentPassword" className='required mb-1'>{t('current_password')}</label>
                                <input type="password" name="currentPassword" id="currentPassword" required className='form-control'
                                    value={currentPassword}
                                    onChange={(e) => {
                                        setCurrentPassword(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="newPassword" className='required mb-1'>{t('new_password')}</label>
                                <input type="password" name="newPassword" id="newPassword" required className='form-control'
                                    value={newPassword}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword" className='required mb-1'>{t("confrim_password")}</label>
                                <input type="password" name="confirmPassword" id="confirmPassword" required className='form-control'
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group mt-4">
                                <Button label={t('update')} className='p-btn' />
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </div>
    );
}
export default QAProfile;