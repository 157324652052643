import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useStore } from 'react-redux';
import { getAppLables, addAppLables, editAppLables } from './../../apis/services';
import { Modal } from 'react-bootstrap';
import { Checkbox } from "primereact/checkbox";
import { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { InputSwitch } from "primereact/inputswitch";
import { setShowDeleteDialog } from '../../redux/reducer';
import DeleteModalContent from '../../commons/DeleteModalContent';
import { getTimeStamp } from '../../helpers/helpers';
import { InputText } from 'primereact/inputtext';
const AppLables = () => {
    const { t } = useTranslation();

    const [lables, setLables] = useState([]);
    useEffect(() => {
        loadAppLables();
    }, []);
    const loadAppLables = async () => {
        try {
            const res = await getAppLables();
            setLables(res.data.translations);
        } catch (error) {

        }
    }
    const cellEditor = (options) => {
        console.log(options);
        
        return textEditor(options);
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />;
    };
    return (
        <div className="p-3 glass-card">
            <DataTable
                value={lables}
                paginator
                rows={10}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10, 25, 50]}
                dataKey="id"
                selectionMode="checkbox"
                filterDisplay="menu"
                emptyMessage={t('data_not_available')}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                editMode='cell'
            >
                <Column
                    field="trans_key"
                    sortField='trans_key'
                    header={t('Key')}
                    sortable
                    body={(r) => {
                        return <span onClick={() => navigator.clipboard.writeText(r.trans_key)}>{r.trans_key}</span>
                    }}
                />
                <Column
                    field="en"
                    sortField="en"
                    header={t('English')}
                    sortable
                    editor={(options) => cellEditor(options)}
                    onCellEditComplete={(e) => {
                        let { rowData, newValue, field, originalEvent: event } = e;
                        rowData[field] = newValue;
                    }}
                />
                <Column
                    field="ar"
                    sortField="ar"
                    header={t('Arabic')}
                    sortable
                    editor={(options) => cellEditor(options)}
                    onCellEditComplete={(e) => {
                        let { rowData, newValue, field, originalEvent: event } = e;
                        rowData[field] = newValue;
                    }}
                />
                  <Column
                            header={t('actions')}
                            body={(row) => {
                                return (
                                    <div className='d-flex'>
                                        <Button
                                            onClick={(e) => {
                                                // setShowEditCollegesModal({ show: true, item: row });
                                            }}
                                            className='icon-btn mx-1' severity='primary' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                edit
                                            </span>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                // store.dispatch(setShowDeleteDialog({ show: true, url: '/colleges/delete/' + row.id }))
                                            }}
                                            className='icon-btn mx-1' severity='danger' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </Button>
                                    </div>
                                )
                            }}
                        />
            </DataTable>
        </div>
    );
}
export default AppLables;