import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { acceptQuote,getPaymentLink } from '../../apis/services';
import { Modal } from 'react-bootstrap';
import { Checkbox } from "primereact/checkbox";
import { Rating } from 'primereact/rating';
import { Toast } from 'primereact/toast';
import { useStore } from 'react-redux';
const AssignmentQuotes = (props) => {
    const hwQuotes = props.hwQuotes;
    const hwData = props.hwData;
    const role = props.role;
    const { t } = useTranslation();
    const toastRef = useRef();
    const store = useStore();
    const wallet = store.getState().app.user.wallet;
    const [rowClick, setRowClick] = useState(true);
    const [selectedQuote, setsSelectedQuote] = useState(null);
    const [showPayModal, setShowPayModal] = useState(false);
    const [payType, setPayType] = useState('wallet');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const handleAcceptQuote = async () => {
        try {
            if (selectedQuote !== null && wallet.current_balance >= selectedQuote.quote_amount) {
                let data = {
                    qid: selectedQuote.id,
                    ins_id: selectedQuote.ins_id,
                    method: payType,
                    hw: selectedQuote.hw_id,
                    amount: selectedQuote.quote_amount,
                }
                await acceptQuote(data);
                setShowPayModal(false);
                props.loadHomeWorkData();
            } else {
                toastRef.current.show({ severity: 'error', summary: t('error'), detail: t('insufficient_wallet_balance'), life: 3000 });
            }
        } catch (error) {
            console.log(error);

        }
    }
    const handleAcceptQuoteViaKNET = async () =>{
        try {
            let data = {
                qid: selectedQuote.id,
                ins_id: selectedQuote.ins_id,
                method: payType,
                hw: selectedQuote.hw_id,
                amount: selectedQuote.quote_amount,
            }
            const res  =  await getPaymentLink(data);
            window.location.assign(res.data.url);
            
        } catch (error) {
            
        }
    }
    return (
        <div>

            <Modal
                show={showPayModal}
            >
                <Toast ref={toastRef} />
                <div className="p-3">
                    <div className="d-flex jcsb align-items-center mb-3">
                        <h4>{t('accept_quote_from_the_instructor')}</h4>
                        <Button className='icon-btn'
                            onClick={() => {
                                setShowPayModal(false);
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                    <h6>{t('to_accept_offer_pay_quote_amount_and_dont_worry')}</h6>
                    <p className='text-center m-3'><strong> KWD {selectedQuote?.quote_amount?.toFixed(3)}</strong></p>
                    <div>
                        <ul
                            style={{
                                listStyle: 'none',
                                padding: 5,
                            }}
                        >
                            <li className='p-2'>
                                <div className='d-flex jcsb'>
                                    <div className="d-flex align-items-center">
                                        <Checkbox inputId="wallet" name="wallet" value="wallet"
                                            onChange={() => {
                                                setPayType('wallet')
                                            }}
                                            checked={payType === 'wallet'}
                                        />
                                        <label htmlFor="wallet" className="mx-2">{t('wallet')}</label>
                                    </div>
                                    {/* <h3>ws</h3> */}
                                </div>
                            </li>
                            <hr />
                            <li className='p-2'>
                                <div className="d-flex jcsb">
                                    <div className="d-flex align-items-center">
                                        <Checkbox inputId="knet" name="knet" value="knet"
                                            onChange={() => {
                                                setPayType('knet')
                                            }}
                                            checked={payType === 'knet'}
                                        />
                                        <label htmlFor="knet" className="mx-2">{t("knet")}</label>
                                    </div>
                                    {/* <h3>ws</h3> */}
                                </div>
                            </li>
                        </ul>
                        <Button label={t('accept_and_pay')} className='p-btn'
                            onClick={() => {
                                if (payType === "wallet") {
                                    handleAcceptQuote();
                                }
                                else{
                                    handleAcceptQuoteViaKNET();
                                }
                            }}
                        />
                    </div>
                </div>
            </Modal>
            <DataTable
                value={hwQuotes}
                paginator
                rows={10}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10, 25, 50]}
                dataKey="id"
                selectionMode={rowClick ? null : 'radiobutton'}
                selection={selectedQuote}
                onSelectionChange={(e) => setsSelectedQuote(e.value)}
                filterDisplay="menu"
                emptyMessage={t('data_not_available')}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                filters={filters}
                header={
                    <div className='row'>
                        <div className="col-md-6">
                            <h5>{t('quotations_received_on_this_hr')}</h5>
                            <input className='form-control'
                                placeholder='Search..'
                                onChange={(e) => {
                                    let _filters = { ...filters };
                                    _filters['global'].value = e.target.value;
                                    setFilters(_filters);
                                }}
                            />
                        </div>
                        {
                            (role === "student" && hwData.has_quote !== 1  && hwQuotes.length !==0) &&
                            <div className="col-md-6">
                                <Button label={t('accept_selected')} className='p-btn'
                                    onClick={() => {
                                        setShowPayModal(true);
                                    }}
                                />
                            </div>
                        }

                    </div>
                }
            >
                {
                    (role === "student" && hwData.has_quote !== 1 && hwQuotes.length !==0) &&
                    <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
                }

                <Column
                    header={t('ins_code')}
                    field='ins_id'
                    body={(r) => {
                        return (
                            <span>INS_00{r.ins_id}</span>
                        );
                    }}
                    sortable
                />
                <Column
                    header={t('ins_rating')}
                    field='ins_id'
                    body={(r) => {
                        return (
                            <span><Rating stars={5} value={r.rating} cancel={false} readOnly disabled /></span>
                        );
                    }}
                    sortable
                />
                <Column
                    field="quote_amount"
                    body={(r) => {
                        return (
                            <span>KWD {r.quote_amount.toFixed(3)}</span>
                        );
                    }}
                    header={t('quote_amount')}
                    sortable
                />

                <Column
                    header={t('resp_time')}
                    field='res_time'
                    sortable

                />
                <Column
                    field="quoteed_at"
                    header={t('date')}
                    sortable
                />

                <Column
                    field="status"
                    body={(r) => {
                        return (
                            <span>{r.status === 0 ? "Sent" : "Accepted"}</span>
                        );
                    }}
                    header={t('status')}
                    sortable
                />
            </DataTable>
        </div>
    );
}
export default AssignmentQuotes;