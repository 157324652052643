import React from 'react';
const TermsConditions = () => {
    return (
        <div className="p-3">
            <h1 className='text-center'>TA-PAL</h1>
            <h4 className='text-center'>Terms & Conditions</h4>
            <h3>Terms and Conditions for Students</h3>
            <ol>
                <li><strong>Introduction</strong>
                    <p>Welcome to [TA-PAL] (“the App”), an anonymous platform connecting students with instructors for academic assistance. By registering and using our services, you agree to comply with these Terms and Conditions.</p>
                </li>
                <li><strong>Eligibility</strong>
                    <p>You must be at least 18 years old to use the App. If you are under 18, you may use the App only with the involvement of a parent or guardian.</p>
                </li>
                <li><strong>Account Registration</strong>
                    <ul>
                        <li>Provide accurate and complete information during registration.</li>
                        <li>Maintain the confidentiality of your login credentials.</li>
                        <li>Notify us immediately of any unauthorized use of your account.</li>
                    </ul>
                </li>
                <li><strong>Use of Services</strong>
                    <ul>
                        <li>Submit requests for assistance by providing clear and relevant information.</li>
                        <li>Review and select instructor quotes based on your preferences.</li>
                        <li>Acknowledge that all interactions with instructors are anonymous, and personal information should not be exchanged.</li>
                    </ul>
                </li>
                <li><strong>Payments</strong>
                    <ul>
                        <li>Deposit funds into your App wallet to cover the cost of services.</li>
                        <li>Payments are held in escrow and released to the instructor upon your approval of the completed task.</li>
                        <li>All transactions are processed through the App; external payments are prohibited.</li>
                    </ul>
                </li>
                <li><strong>Content and Intellectual Property</strong>
                    <ul>
                        <li>You retain ownership of any content you submit.</li>
                        <li>Grant the App a non-exclusive, royalty-free license to use your content for service provision.</li>
                        <li>Do not submit content that infringes on third-party rights or is unlawful.</li>
                    </ul>
                </li>
                <li><strong>Privacy and Confidentiality</strong>
                    <ul>
                        <li>Your anonymity is a priority; personal information will not be shared with instructors.</li>
                        <li>Do not attempt to uncover the identity of instructors or share your own personal information.</li>
                        <li>Review our Privacy Policy for detailed information on data handling.</li>
                    </ul>
                </li>
                <li><strong>User Conduct</strong>
                    <ul>
                        <li>Use the App responsibly and ethically.</li>
                        <li>Do not engage in activities that could harm the App or other users.</li>
                        <li>Report any suspicious or inappropriate behavior to us promptly.</li>
                    </ul>
                </li>
                <li><strong>Limitation of Liability</strong>
                    <ul>
                        <li>The App is provided “as is” without warranties of any kind.</li>
                        <li>We are not liable for any indirect, incidental, or consequential damages arising from your use of the App.</li>
                    </ul>
                </li>
                <li><strong>Termination</strong>
                    <ul>
                        <li>We reserve the right to suspend or terminate your account for violations of these Terms.</li>
                        <li>You may terminate your account at any time by contacting us.</li>
                    </ul>
                </li>
                <li><strong>Amendments</strong>
                    <ul>
                        <li>We may update these Terms periodically.</li>
                        <li>Continued use of the App constitutes acceptance of the revised Terms.</li>
                    </ul>
                </li>
                <li><strong>Governing Law</strong>
                    <ul>
                        <li>These Terms are governed by the laws of [Your Jurisdiction].</li>
                        <li>Any disputes will be resolved in the courts of [Your Jurisdiction].</li>
                    </ul>
                </li>
                <li><strong>Contact Information</strong>
                    <ul>
                        <li>For questions or concerns, contact us at [Contact Information].</li>
                    </ul>
                </li>
            </ol>
            <h3>Terms and Conditions for Instructors</h3>
            <ol>
                <li><strong>Introduction</strong>
                    <p>Welcome to [TA-PAL] (“the App”), an anonymous platform connecting instructors with students seeking academic assistance. By registering and providing services through our App, you agree to comply with these Terms and Conditions.</p>
                </li>
                <li><strong>Eligibility</strong>
                    <ul>
                        <li>You must be at least 18 years old to use the App.</li>
                        <li>Possess the necessary qualifications and expertise in your subject area.</li>
                    </ul>
                </li>
                <li><strong>Account Registration</strong>
                    <ul>
                        <li>Provide accurate and complete information during registration.</li>
                        <li>Maintain the confidentiality of your login credentials.</li>
                        <li>Notify us immediately of any unauthorized use of your account.</li>
                    </ul>
                </li>
                <li><strong>Provision of Services</strong>
                    <ul>
                        <li>Review student requests and submit quotes based on your expertise and availability.</li>
                        <li>Deliver high-quality assistance within the agreed timeframe.</li>
                        <li>All interactions with students are anonymous; personal information should not be exchanged.</li>
                    </ul>
                </li>
                <li><strong>Payments</strong>
                    <ul>
                        <li>Payments are held in escrow and released to your App wallet upon student approval of the completed task.</li>
                        <li>All transactions are processed through the App; external payments are prohibited.</li>
                        <li>The App deducts a service fee from each transaction; details are provided in your account dashboard.</li>
                    </ul>
                </li>
                <li><strong>Content and Intellectual Property</strong>
                    <ul>
                        <li>Ensure that all content you provide is original and does not infringe on third-party rights.</li>
                        <li>Grant the App a non-exclusive, royalty-free license to use your content for service provision.</li>
                    </ul>
                </li>
                <li><strong>Privacy and Confidentiality</strong>
                    <ul>
                        <li>Maintain the anonymity of students; do not attempt to uncover their identities.</li>
                        <li>Do not share your personal information with students.</li>
                        <li>Review our Privacy Policy for detailed information on data handling.</li>
                    </ul>
                </li>
                <li><strong>User Conduct</strong>
                    <ul>
                        <li>Use the App responsibly and ethically.</li>
                        <li>Do not engage in activities that could harm the App or other users.</li>
                        <li>Report any suspicious or inappropriate behavior to us promptly.</li>
                    </ul>
                </li>
                <li><strong>Limitation of Liability</strong>
                    <ul>
                        <li>The App is provided “as is” without warranties of any kind.</li>
                        <li>We are not liable for any indirect, incidental, or consequential damages arising from your use of the App.</li>
                    </ul>
                </li>
                <li><strong>Termination</strong>
                    <ul>
                        <li>We reserve the right to suspend or terminate your account for violations of these Terms.</li>
                        <li>You may terminate your account at any time by contacting us.</li>
                    </ul>
                </li>
                <li><strong>Amendments</strong>
                    <ul>
                        <li>We may update these Terms periodically.</li>
                        <li>Continued use of the App constitutes acceptance of the revised Terms.</li>
                    </ul>
                </li>
                <li><strong>Governing Law</strong>
                    <ul>
                        <li>These Terms are governed by the laws of [Your Jurisdiction].</li>
                        <li>Any disputes will be resolved in the courts of [Your Jurisdiction].</li>
                    </ul>
                </li>
                <li><strong>Contact Information</strong>
                    <ul>
                        <li>For questions or concerns, contact us at [Contact Information].</li>
                    </ul>
                </li>
                <li><strong>Additional Considerations</strong>
                    <p>To further protect the app owner and ensure the privacy and security of all users, consider incorporating the following clauses into both the Student and Instructor Terms and Conditions:</p>
                    <ol>
                        <li><strong>Dispute Resolution</strong>
                            <ul>
                                <li>Informal Resolution: Encourage users to contact the app’s support team to resolve any disputes amicably before pursuing formal action.</li>
                                <li>Arbitration Agreement: Specify that any unresolved disputes will be settled through binding arbitration, detailing the process and governing rules.</li>
                            </ul>
                        </li>
                        <li><strong>Confidentiality</strong>
                            <ul>
                                <li>Non-Disclosure: Users agree not to disclose any confidential information obtained through the app, including but not limited to proprietary materials, methodologies, or user identities.</li>
                                <li>Survival: Clarify that confidentiality obligations survive the termination of the user’s account.</li>
                            </ul>
                        </li>
                        <li><strong>Indemnification</strong>
                            <ul>
                                <li>Users agree to indemnify and hold harmless the app owner from any claims, damages, or expenses arising from their use of the app, violation of the Terms, or infringement of third-party rights.</li>
                            </ul>
                        </li>
                        <li><strong>Severability</strong>
                            <ul>
                                <li>State that if any provision of the Terms is found to be unenforceable, the remaining provisions will continue in full force and effect.</li>
                            </ul>
                        </li>
                        <li><strong>Entire Agreement</strong>
                            <ul>
                                <li>Affirm that these Terms constitute the entire agreement between the user and the app owner, superseding any prior agreements or understandings.</li>
                            </ul>
                        </li>
                        <li><strong>Waiver</strong>
                            <ul>
                                <li>Specify that the app owner’s failure to enforce any right or provision of the Terms does not constitute a waiver of such right or provision.</li>
                            </ul>
                        </li>
                        <li><strong>Assignment</strong>
                            <ul>
                                <li>Inform users that the app owner may assign its rights and obligations under the Terms without user consent, while users may not assign their rights without prior written consent.</li>
                            </ul>
                        </li>
                        <li><strong>Force Majeure</strong>
                            <ul>
                                <li>Clarify that the app owner is not liable for any failure to perform due to causes beyond its reasonable control, such as natural disasters, war, or governmental action.</li>
                            </ul>
                        </li>
                        <li><strong>Feedback</strong>
                            <ul>
                                <li>Encourage users to provide feedback and grant the app owner a royalty-free, perpetual license to use such feedback to improve services.</li>
                            </ul>
                        </li>
                    </ol>
                </li>
            </ol>

        </div>
    );
}
export default TermsConditions;