import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getStudentPayouts } from '../../apis/services';
const Studentpayouts = () => {
    const { t } = useTranslation();
    const [payouts, setPayouts] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    useEffect(() => {
        loadGetStudentPayouts();
    }, []);
    const loadGetStudentPayouts = async () => {
        try {
            const res = await getStudentPayouts();
            setPayouts(res.data.payouts);
        } catch (error) {

        }
    }
    return (
        <div className="p-3 glass-card">
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('payout_money_transfers')}</h4>
                    <h6>{t('Transfers you have made to different instructors for providing answers to your Help Requests')}</h6>
                </div>
                <div className='p-2'>
                    {/* <Link to="" className='link-btn'>Invite Instructors</Link> */}
                </div>
            </div>
            <div className="dats-table p-3">
                <DataTable
                    value={payouts}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    filters={filters}
                    header={
                        <div className='row'>
                            <div className="col-md-6">
                                <input className='form-control'
                                    placeholder={t('search')}
                                    onChange={(e) => {
                                        let _filters = { ...filters };
                                        _filters['global'].value = e.target.value;
                                        setFilters(_filters);
                                    }}
                                />
                            </div>

                        </div>
                    }
                >

                    <Column
                        field="hw_id"
                        header={t('hr_id')}
                        sortable
                        body={(row) => {
                            return (
                                <span>{'HR_'}{row.hw_id}</span>
                            )
                        }}
                    />
                    <Column
                        field="qid"
                        header={t('quote_id')}
                        sortable
                        body={(row) => {
                            return (
                                <span>{'QU_'}{row.qid}</span>
                            )
                        }}
                    />
                    <Column

                        field="ins_id"
                        header={t('transferred_to')}
                        sortable
                        body={(row) => {
                            return (
                                <span>{'INS_00'}{row.ins_id}</span>
                            )
                        }}
                    />
                    <Column
                        field="amount"
                        header={t('transferred_amount')}
                        sortable
                        body={(row) => {
                            return (
                                <span>{'KWD '}{row.amount.toFixed(3)}</span>
                            )
                        }}
                    />
                    <Column
                        field="accepted_at"
                        header={t('transferred_at')}
                        sortable
                        body={(row) => {
                            return (
                                <span>{row.accepted_at}</span>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    );
}
export default Studentpayouts;