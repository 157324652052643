import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from '../../redux/store';
import { Button } from 'primereact/button';
import {createPayoutRequest} from '../../apis/services';
import { useNavigate } from 'react-router-dom';
const RequestPayout = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userStore = store.getState().app.user;
    const [amount,setAmount] = useState();
    const [currency,setCurrency] = useState('KWD');
    const handleCreatePayoutRequest = async ()=>{
        try {
            let data={
                amount:amount,
                currency:currency
            }
            await createPayoutRequest(data);
            navigate('/ins/payout-requests');
        } catch (error) {
            
        }
    }
    return (
        <div className="p-3 glass-card">
            <h4>{t('req_new_payout_req')}</h4>
            <h5>{t('current_balance')} - KWD {userStore.wallet.current_balance.toFixed(3)}</h5>
            <form action=""
                onSubmit={(e)=>{
                    e.preventDefault();
                    handleCreatePayoutRequest();
                }}
            >
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="amount" className='mb-1 required'>{t('amount')}</label>
                            <input type="text" className='form-control' 
                                value={amount}
                                onChange={(e)=>{
                                    setAmount(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="curency" className='mb-1 required'>{t('curency')}</label>
                            <input type="text" className='form-control' 
                                value={currency}
                                onChange={(e)=>{
                                    setCurrency(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <Button label={t('send_request')} className='p-btn'/>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default RequestPayout;