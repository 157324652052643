import React, { useState, useEffect, useRef } from 'react';
import { updateStudentProfile, updateUserPassword, getCollegesByCountries,getRegisterData } from './../../apis/services';
import { Button } from 'primereact/button';
import store from '../../redux/store';
import { Toast } from 'primereact/toast';
import Select from 'react-select';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
const StudentPofile = () => {
    const toastRef = useRef();
    const { t } = useTranslation();
    const userStore = store.getState().app.user;
    const user = userStore.user;
    const [aname, setAname] = useState(user.alias_name);
    const [phone, setPhone] = useState(user.phone);
    const [currentPassword, setCurrentPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [selectedCollege, setSelectedCollege] = useState({id:userStore.more.college_id});
    const [colleges, setColleges] = useState([]);
    const [major, setMajor] = useState();
    const [majors, setMajors] = useState([]);
    const [country, setCountry] = useState();
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        loadGetRegisterData();
    }, []);
    const loadGetRegisterData = async () => {
        try {
            const res = await getRegisterData();
            setMajors(res.data.majors);
            setMajor(res.data.majors.filter((m) => m.id === userStore.more.major_id)[0]);
            setCountries(res.data.countries);            
            const existCountry = res.data.countries.find((c)=> user.country.split(',').includes(c.id.toString()))
            setCountry(existCountry);
            loadColleges(existCountry)
        } catch (error) {

        }
    }
    const handleUpdateUser = async () => {
        try {
            let data = {
                aname: aname,
                phone: phone,
                clg:selectedCollege.id,
                major:major.id,
                country:country.id,
            }
            await updateStudentProfile(data);
            window.location.reload();

        } catch (error) {

        }
    }
    const handleUpdateUserPassword = async () => {
        try {
            if (newPassword !== confirmPassword) {
                toastRef.current.show({ severity: 'error', summary: t('error'), detail: t('passwords_not_matching'), life: 3000 });
            } else {
                let data = {
                    newPassword: newPassword,
                    currentPassword: currentPassword
                }
                const res = await updateUserPassword(data);
                if (res.data.status) {
                    toastRef.current.show({ severity: 'success', summary: t('success'), detail: t('password_changed'), life: 3000 });
                } else {
                    toastRef.current.show({ severity: 'error', summary: t('error'), detail: t('password_change_error'), life: 3000 });
                }

            }

        } catch (error) {

        }
    }
    const loadColleges = async (list) => {
        try {
            let data = {
                cids: [list.id],
            }
            const res = await getCollegesByCountries(data);
            setColleges(res.data.colleges);
            let existingClgs = res.data.colleges.find((c)=> c.colleges.find((cl)=> cl.id === userStore.more.college_id));
            setSelectedCollege( existingClgs.colleges.find((cl)=> cl.id === userStore.more.college_id));
        } catch (error) {

        }
    }
    return (
        <div className="p-3">
            <Toast ref={toastRef} />
            <div className="row">
                <div className="col-md-8 mb-2">
                    <div className="glass-card p-3">
                        <h5>{t('student_profile')}</h5>
                        <form action=""
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleUpdateUser();
                            }}
                        >
                            <div className="form-group">
                                <label htmlFor="alias_name" className='required mb-1'>{t('alias_name')}</label>
                                <input type="text" name="alias_name" id="alias_name" required className='form-control'
                                    value={aname}
                                    onChange={(e) => {
                                        setAname(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="code" className='required mb-1'>{t('code')}</label>
                                <input type="text" name="code" id="code" required className='form-control' disabled readOnly value={user.code} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email" className='required mb-1'>{t('email')}</label>
                                <input type="email" name="email" id="email" required className='form-control' disabled readOnly value={user.email} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone" className='required mb-1'>{t('phone')}</label>
                                <input type="text" name="phone" id="phone" required className='form-control'
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="country" className='mb-1'>{t('country')}</label>
                                        <Select options={countries}
                                            getOptionLabel={(l) => l.country_name}
                                            getOptionValue={(v) => v.id}
                                            value={country}
                                            placeholder={t('country')}
                                            onChange={(e) => {
                                                setCountry(e)
                                                setSelectedCollege([]);
                                                loadColleges(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="collge" className='mb-1'>{t('college')}</label>
                                        <Dropdown
                                            className="pr-input"
                                            optionGroupLabel="cc"
                                            optionLabel="name"
                                            optionGroupChildren="colleges"
                                            options={colleges}
                                            filter
                                            placeholder={t('choose_college')}
                                            value={selectedCollege}
                                            onChange={(e) => setSelectedCollege(e.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="major" className='required mb-1'>{t('major')}</label>
                                <Select options={majors}
                                    value={major}
                                    onChange={(e) => {
                                        setMajor(e)
                                    }}
                                    required
                                    getOptionLabel={(e) => e.name}
                                    getOptionValue={(e) => e.id}
                                />
                            </div>
                            <div className="form-group mt-4">
                                <Button label={t('update')} className='p-btn' />
                            </div>
                        </form>
                    </div>
                </div>


                <div className="col-md-4 mt-3">
                    <div className="glass-card p-3">
                        <form action=""
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleUpdateUserPassword();
                            }}
                        >
                            <div className="form-group">
                                <label htmlFor="currentPassword" className='required mb-1'>{t('current_password')}</label>
                                <input type="password" name="currentPassword" id="currentPassword" required className='form-control'
                                    value={currentPassword}
                                    onChange={(e) => {
                                        setCurrentPassword(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="newPassword" className='required mb-1'>{t('new_password')}</label>
                                <input type="password" name="newPassword" id="newPassword" required className='form-control'
                                    value={newPassword}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword" className='required mb-1'>{t('confrim_password')}</label>
                                <input type="password" name="confirmPassword" id="confirmPassword" required className='form-control'
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group mt-4">
                                <Button label='Update' className='p-btn' />
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </div>
    );
}
export default StudentPofile;