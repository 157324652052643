import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getINSAssignments } from './../../apis/services';
import Answered from './tabs/Answered';
import { TabView, TabPanel } from 'primereact/tabview';
import PaidHRs from './tabs/PaidHRs';
import InstPreffered from './tabs/InstPreffered';
import FromSubjects from './tabs/FromSubjects';
import FromYourCollgeList from './tabs/FromYourCollgeList';
import QCPassed from './../assignments/tabs/QCPassed';
const INSAssignments = () => {
    const { t } = useTranslation();
    const [assignments, setAssignments] = useState([]);

    useEffect(() => {
        loadINSAssignments();
    }, []);
    const loadINSAssignments = async () => {
        try {
            let data = {

            };
            const res = await getINSAssignments(data);
            setAssignments(res.data.homeworks);
        } catch (error) {

        }
    }
    return (
        <div>
            <div className="glass-card p-3">
                <div className="data-table mt-2">
                    <TabView>
                        <TabPanel header={t('preferred_you')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">account_circle</span> }}>
                            <InstPreffered assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('from_subs_you_teach')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">local_library</span> }}>
                            <FromSubjects assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('from_your_clg')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">school</span> }}>
                            <FromYourCollgeList assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('all_hrs')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">menu_book</span> }}>
                            <QCPassed assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('answered')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">check_circle</span> }}>
                            <Answered assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('paid')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">new_releases</span> }}>
                            <PaidHRs assignments={assignments} />
                        </TabPanel>

                    </TabView>
                </div>
            </div>
        </div>
    );
}
export default INSAssignments;