import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useStore } from 'react-redux';
import { getAllAssignmentQuotes, editINSAssignmentQuotes } from '../../apis/services';
import { Modal } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Badge } from 'primereact/badge';
const AcceptedQuotes = (props) => {
    const { t } = useTranslation();
    const store = useStore();
    const user = store.getState().app.user.user;
    const hrQuotes = props.hrQuotes.filter((i) => i.status === 1);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });


    return (
        <div>
            <div className="">
                <h6>{t('student_accepted_quotes_are_time_bounded_completing_them_within_due_date_is_required.')}.</h6>
                <div className="data-table mt-2">
                    <DataTable
                        value={hrQuotes}
                        paginator
                        rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="menu"
                        emptyMessage={t('data_not_available')}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        filters={filters}
                        header={
                            <div className='row'>
                                <div className="col-md-6">
                                    <input className='form-control'
                                        placeholder='Search..'
                                        onChange={(e) => {
                                            let _filters = { ...filters };
                                            _filters['global'].value = e.target.value;
                                            setFilters(_filters);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    >

                        <Column
                            field="hw_id"
                            header={t('hr_id')}
                            sortable
                            body={(r) => {
                                return (
                                    <span>HR_{r.hw_id}</span>
                                );
                            }}
                        />
                        <Column
                            field="quote_amount"
                            body={(r) => {
                                return (
                                    <span>KWD {r.quote_amount.toFixed(3)}</span>
                                );
                            }}
                            header={t('quote_amount')}
                            sortable
                        />

                        <Column
                            header={t('resp_time')}
                            field='res_time'
                        />
                        <Column
                            field="quoteed_at"
                            header={t('date')}
                            sortable
                        />
                        <Column
                            field="due_date"
                            header={t('due_date')}
                            body={(r) => {
                                return (
                                    <Badge value={r.due_date} />
                                );
                            }}
                            sortable
                        />
                        <Column
                            header={t('actions')}
                            body={(row) => {
                                return (
                                    <div className='d-flex'>
                                        <Link
                                            to="/assignments/view"
                                            state={row.hw_id}
                                            className='link-icon-btn' >
                                            <span className="material-symbols-outlined">
                                                visibility
                                            </span>
                                        </Link>
                                    </div>
                                )
                            }}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
export default AcceptedQuotes;