import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getInsPayoutRequests } from '../../apis/services';
const InsPayoutRequests = () => {
    const { t } = useTranslation();
    const [ins, setIns] = useState([]);
    useEffect(() => {
        loadGetInsPayoutRequests();
    }, []);
    const loadGetInsPayoutRequests = async () => {
        try {
            const res = await getInsPayoutRequests();
            setIns(res.data.payouts	);
        } catch (error) {

        }
    }
    return (
        <div className="p-3 glass-card">
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('Manage Payout Requests')}</h4>
                </div>
                <div className='p-2'>
                    <Link to="/ins/payout-requests/create"className='link-btn'>{t('new_payment_request')}</Link>
                </div>
            </div>
            <div className="data-table mt-2">
                <DataTable
                    value={ins}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                >

                    <Column
                        field="id"
                        header={t('PR ID')}
                        sortable
                    />
                    <Column
                        field="amount"
                        header={t('Amount')}
                        sortable
                    />
                    <Column
                        field="req_date"
                        header={t('Requsted Date')}
                        sortable
                    />
                    <Column
                        field="status"
                        header={t('Status')}
                        sortable
                    />
                    <Column
                        field="processed_date"
                        header={t('Processed Date')}
                        sortable
                    />
                    <Column
                        field="req_currency"
                        header={t('Requested Currency')}
                        sortable
                    />
                     <Column
                        field="req_currency_amount"
                        header={t('Requested Currency Amount')}
                        sortable
                    />
                    <Column
                        header="Actions"
                        body={(row) => {
                            return (
                                <div className='d-flex'>
                                    {/* <Link
                                        state={JSON.stringify(row)}
                                        to='/instructors/view'
                                        className='link-icon-btn mx-1' severity='primary' id="edit-btn">
                                        <span className="material-symbols-outlined">
                                            visibility
                                        </span>
                                    </Link> */}
                                </div>
                            );
                        }}
                    />
                </DataTable>

            </div>
        </div>
    );
}

export default InsPayoutRequests;